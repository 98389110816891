import React from 'react';
import { FieldProps } from 'formik';
import { Col } from 'antd/lib/grid';

import { Nullable } from '@common/typescript/objects/Nullable';
import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { ServiceType } from '@app/objects/Pet';
import { PriceType } from '@app/objects/Price';
import { InventoryItem, ItemCategory } from '@app/objects/Inventory';
import { noUrnId } from '@app/components/Various/PriceEditor/services';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { ProductSelect } from '@app/components/UI/Inputs/ProductSelect';

interface ProductSelectFormProps {
	fieldProps: FieldProps<FormValues>;
	serviceType: ServiceType;
	priceType: PriceType;

	clinicId: Nullable<number>;
	crematoryId: number;

	category: ItemCategory;
	availableForCrematory?: boolean;
	getName?: (value: InventoryItem) => string;
}

function getLabel(category: ItemCategory) {
	return category === ItemCategory.Urn ? 'Urn' : 'Product';
}

/// This is used to display 'No Urn' in the list
const local = (name: string, category: ItemCategory): Array<InventoryItem> => {
	return [{
		id: noUrnId,
		name: `No ${name}`,
		fullName: '',
		description: `This price is used when no ${name}'s selected`,

		crematory: null,

		measuringUnit: null,
		measuringUnitId: -1,

		quantity: 0,
		lowerLimit: 0,
		category,

		avatar: '',
		originalAvatar: '',

		isDefault: false,
		isSpecial: false,

		parent: null,
		parentId: -1,

		storeNode: null,
		storeEntryVariants: [],
	}];
};

export const ProductSelectForm: React.FC<ProductSelectFormProps> = ({
	crematoryId, clinicId, serviceType,
	priceType, availableForCrematory,
	category, fieldProps,
	getName,
}: ProductSelectFormProps) => {
	const label = getLabel(category);

	return (
		<Col xs={24} sm={24} md={12}>
			<FormikInput
				fieldProps={fieldProps}
				title={label}
				containerClassName=""
				render={({ field, form }) => (
					<ProductSelect
						value={field.value}
						onChange={(value) => form.setFieldValue(field.name, value)}
						crematoryId={crematoryId}
						clinicId={clinicId}
						serviceType={serviceType}
						priceType={priceType}
						category={category}
						availableForCrematory={availableForCrematory}

						placeholder="Select the product"
						localOptions={local(label.toLowerCase(), category)}
						getName={getName}
						withGroup
						withParent
					/>
				)}
			/>
		</Col>
	);
};
