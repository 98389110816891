import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Col, Row } from 'antd/lib/grid';
import { Field, FieldProps, useFormikContext } from 'formik';

import { Nullable } from '@common/typescript/objects/Nullable';

import { ApplicationState } from '@app/store';
import { ServiceType } from '@app/objects/Pet';
import { PriceKind, PriceType } from '@app/objects/Price';
import { GeneralKey } from '@app/store/SelectList/SelectList';
import { InventoryItem, ItemCategory } from '@app/objects/Inventory';
import { getActionCreators } from '@app/store/SelectList/ListActions';
import { noUrnId } from '@app/components/Various/PriceEditor/services';
import { FormValues } from '@app/components/Various/PriceEditor/PriceEditor';
import { isEqualProduct, ProductSelectFilter } from '@app/store/SelectList/SelectsInterfaces';
import { Stack, StackDirection } from '@app/components/Layout/Stack';
import { BatchPriceFields } from '@app/components/Various/PriceEditor/PriceEditorComponents/BatchPriceFields';
import { ProductSelectForm } from '@app/components/Various/PriceEditor/PriceEditorComponents/ProductPrice/ProductSelectForm';
import { ProductTable } from '@app/components/Various/PriceEditor/PriceEditorComponents/ProductPrice/ProductTable';
import { NoItemCheckbox } from '@app/components/Various/PriceEditor/PriceEditorComponents/ProductPrice/NoItemCheckbox';
import { hasNoItemIdInArray } from '@app/components/Various/PriceEditor/schemas';
import { FormikField } from '@app/components/Forms/FormikField';

interface ProductProps {
	crematoryId: number;
	clinicId: Nullable<number>;
	availableForCrematory?: boolean;

	serviceType: ServiceType;
	priceType: PriceType;

	priceKind: PriceKind;

	isModalOpen: boolean;
	needRefresh: boolean;
	setNeedRefresh: (value: boolean) => void;
}

const reqParams = {
	withDefault: true,
	withParent: true,
	groupByPrice: false,
};

const getName = (item: InventoryItem) => (item.id === -13 ? 'No Urn' : item.fullName);
export const ProductPriceComponent: React.FC<ProductProps> = ({
	crematoryId,
	clinicId,
	serviceType,
	priceType,
	priceKind,
	availableForCrematory,
	needRefresh,
	isModalOpen,
	setNeedRefresh,
}): JSX.Element => {
	const dispatch = useDispatch();
	const { values } = useFormikContext<FormValues>();
	const store = useSelector((state: ApplicationState) => state.selects.products[GeneralKey].items);
	const [noItem, setNoItem] = React.useState<boolean>(false);
	const productItem = store.find((item) => item.id === values.inventoryItemId);
	const reqSelect = React.useMemo(() =>
		bindActionCreators(
			getActionCreators(
				'products',
				{
					endpoint: 'inventoryItemList',
					key: GeneralKey,
					isEqual: isEqualProduct,
				},
			),
			dispatch,
		), [dispatch]);
	const category = React.useMemo(() => {
		return priceKind === PriceKind.UrnPrice ? ItemCategory.Urn : ItemCategory.Product;
	}, [priceKind]);
	const filters: ProductSelectFilter = React.useMemo(() => ({
		availableForClinic: clinicId,
		availableForCrematory,
		search: '',
		crematoryId,
		category: [category],
		serviceType,
		priceType,
	}), [crematoryId, serviceType, priceType, clinicId, category]);

	React.useEffect(() => {
		if (!isModalOpen) return;

		if (needRefresh) {
			reqSelect.refresh(filters, reqParams);
			setNeedRefresh(false);
		}

		if (values.id > 0
			&& store.length
			&& !productItem
			&& values.inventoryItemId
			&& values.inventoryItemId !== noUrnId
			&& !Array.isArray(values.inventoryItemId)) {
			reqSelect.refresh(filters, { preselect: [values.inventoryItemId] });
		}
	}, [values.inventoryItemId, values.id, filters, needRefresh]);

	return (
		<Field name="inventoryItemId">
			{(fieldProps: FieldProps<number, FormValues>) => (
				<Stack direction={StackDirection.Vertical}>
					<Row gutter={[30, 16]}>
						<BatchPriceFields disabled={fieldProps.field.value === noUrnId} />

						<Col xs={24} sm={24} md={8}>
							<FormikField
								fieldName="value"
								title="Price*"
								inputProps={{
									type: 'number',
									min: values.inventoryItemId === noUrnId || hasNoItemIdInArray(values.inventoryItemId) ? undefined : 0,
									inputMode: 'decimal',
								}}
							/>
						</Col>
					</Row>

					<Stack direction={StackDirection.Vertical} gap={3}>
						<NoItemCheckbox
							priceId={values.id}
							noItem={noItem}
							onChange={setNoItem}
							priceKind={priceKind}
						/>

						<Field name="inventoryItemId">
							{(fieldProps: FieldProps<FormValues>) => {
								if (noItem) return null;
								if (values.id && Number(values.id) > 0) {
									return (
										<ProductSelectForm
											serviceType={serviceType}
											priceType={priceType}
											clinicId={clinicId}
											crematoryId={crematoryId}
											availableForCrematory={availableForCrematory}
											category={category}
											fieldProps={fieldProps}
											getName={getName}
										/>
									);
								}

								return <ProductTable params={filters} priceKind={priceKind} />;
							}}
						</Field>
					</Stack>
				</Stack>
			)}
		</Field>
	);
};
